<!-- 服务，需求等管理 -->
<template>
  <div class="resource_require page_width">
    <div class="page_header">
      <page-header icon="iconziyuanguanli" label="服务/需求/成果" />
    </div>
    <div class="page_main">
      <div class="page_main_left custom_menu">
        <div class="menu_wrapper custom_menu">
          <el-menu
            class="my-el-menu-no-border"
            :default-active="$route.meta.sidebar"
            :router="true"
            @select="onSelect">
            <el-menu-item index="/resource-require/service">
              <p class="menu_item_title" slot="title">服务产品</p>
            </el-menu-item>
            <el-menu-item index="/resource-require/require">
              <p class="menu_item_title" slot="title">需求库</p>
            </el-menu-item>
            <!-- <el-menu-item index="/resource-require/expert">
              <p class="menu_item_title" slot="title">专家团队</p>
            </el-menu-item> -->
            <el-menu-item index="/resource-require/achievement">
              <p class="menu_item_title" slot="title">成果管理</p>
            </el-menu-item>
            <template v-if="role !== globalVar.ADMIN_ROLE_2">
              <el-menu-item index="">
                <p class="menu_item_title" slot="title">资源池可视化</p>
              </el-menu-item>
              <el-menu-item index="/resource-require/service-type-manage">
                <p class="menu_item_title" slot="title">服务类型维护</p>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </div>
      <div class="page_main_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data () {
    return {
      url: '' // 获取资源池地址
    }
  },
  created () {
    // 获取资源池地址
    this.getResourcepoolURL()
  },
  methods: {
    onSelect (index, indexPath) {
      console.log(index, indexPath)
      if (index === '') {
        // 资源可视化平台
        window.open(this.url, '_blank')
      }
    },
    /**
     * 获取资源池地址
     */
    getResourcepoolURL () {
      api.getResourcepoolURL().then(res => {
        if (res.data.code === 0) {
          this.url = res.data.data.url
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.resource_require {
  margin-bottom: 25px;
  .page_header {
    padding: 30px 0 20px 0;
  }
  .page_main {
    display: flex;
    .page_main_left {
      .menu_wrapper {
        width: 200px;
        margin-right: 20px;
        padding: 10px 0;
        background: #FFFFFF;
        border: 1px solid #DCDFE6;
        // box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        overflow: hidden;
      }
    }
    .page_main_right {
      width: 980px;
      border-radius: 4px;
      // overflow: hidden;
    }
  }
  .custom_menu {
    .menu_item_title, .sub_menu_item_title {
      position: relative;
      color: #606266;
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        left: -20px;
        width: 4px;
        background-color: transparent;
      }
    }
    .sub_menu_item_title {
      &::before {
        left: -40px;
      }
    }
    .el-menu-item.is-active {
      .menu_item_title, .sub_menu_item_title {
        color: #3473E6;
        font-weight: bold;
        &::before {
          background-color: #3473E6;
        }
      }
    }
  }
}
</style>
